import { BookOpenIcon, BuildingLibraryIcon, ChevronRightIcon, GiftIcon, LockClosedIcon, PuzzlePieceIcon, ScaleIcon, StarIcon } from '@heroicons/react/20/solid'
import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from './components/button';

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const goals = [
    { id: 1, name: 'Funds towards a house deposit' },
    { id: 2, name: 'General financial cushion' },
    { id: 3, name: 'To get in to the habit of having investments ' },
    { id: 4, name: 'Something put aside for a rainy day' },
    { id: 5, name: 'Financial education' }
]

const options = [
    {
        id: 0,
        title: 'Open First Portfolio accounts for your child',
        description: 'Use a tax-efficient Junior Stocks and Shares ISA to create an investment portfolio for your child.',
        icon: <BookOpenIcon className="w-12 h-12 mx-3 text-fp-green-500" />
    },
    {
        id: 1,
        title: 'Contribute to the Portfolio of a child you know',
        description: 'Play a part in ensuring a child you know and love starts financial life on the right foot.',
        icon: <PuzzlePieceIcon className="w-12 h-12 mx-3 text-fp-green-500" />
    },
    {
        id: 2,
        title: 'Open First Portfolio accounts and contribute to others',
        description: 'Open a Junior Stocks and Shares ISA for your child and contribute to the portfolio of other children in your life.',
        icon: <GiftIcon className="w-12 h-12 mx-3 text-fp-green-500" />
    },
]

const riskOptions = [
    {
        id: 0,
        title: 'Cautious',
        description: 'Minimise loss with small movements up and down, with the aim of beating inflation.',
        icon: <LockClosedIcon className="w-8 h-8 ml-1 mr-3 text-fp-green-500" />
    },
    {
        id: 1,
        title: 'Balanced Bag',
        description: 'Limit loss with moderate movements up and down, with the aim of achieving reasonable growth.',
        icon: <ScaleIcon className="w-8 h-8 ml-1 mr-3 text-fp-green-500" />
    },
    {
        id: 2,
        title: 'Confident',
        description: 'Be exposed to higher growth upside, but also higher risk and volatility.',
        icon: <StarIcon className="w-8 h-8 ml-1 mr-3 text-fp-green-500" />
    },
]

export default function Setup() {

    const navigate = useNavigate();
    const [values, setValues] = useState({ usageIntent: false });
    const [page, setPage] = useState(0);
    const [addChildStep, setAddChildStep] = useState(0)
    const [bothInformationStep, setBothInformationStep] = useState(0);

    const submit = async (riskAppetite) => {
        await fetch(`${process.env.REACT_APP_API_URL}/private/setup`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.getItem("token")
            }),
            body: JSON.stringify({ values: { ...values, riskAppetite: riskAppetite } })
        }).then(x => {
            navigate('/home');
            return;
        })
    }

    if (page === 0) {
        return <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <div className="text-center text-2xl font-medium text-gray-800 mb-20">
                    How do you intend to use First Portfolio?
                </div>
                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                    <ul role="list" className="divide-y divide-gray-200">
                        {options.map((option) => (
                            <li key={option.id}>
                                <div onClick={() => { setValues({ ...values, usageIntent: option.id }); (option.id === 0 || option.id === 2) ? setPage(1) : submit(null) }} className="block hover:bg-gray-50">
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                            <div>
                                                <div className="flex text-md">
                                                    {option.icon}
                                                    <p className="font-medium text-fp-gray-500">{option.title}</p>
                                                </div>
                                                <div className="mt-2 flex">
                                                    <div className="flex items-center text-sm text-fp-green-700">
                                                        <p>
                                                            {option.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ml-5 flex-shrink-0">
                                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    }
    if (page === 1) {
        return <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
            <div className="flex flex-col h-full">
                {(values.usageIntent === 0 || values.usageIntent === 2) && <div className="text-center text-2xl font-medium text-gray-800">
                    Junior Stocks and Shares ISA
                    <BookOpenIcon className="h-12 w-12 text-fp-green-500 mx-auto mt-2"></BookOpenIcon>
                </div>}
                {values.usageIntent === 1 && <div className="text-center text-2xl font-medium text-gray-800">
                    Contribute to a child's portfolio
                    <PuzzlePieceIcon className="h-12 w-12 text-fp-green-500 mx-auto mt-2"></PuzzlePieceIcon>
                </div>}

                {/* {values.usageIntent === 2 && <>
                    {bothInformationStep === 0 && <div className="text-center text-2xl font-medium text-gray-800">
                        Junior Stocks and Shares ISA
                        <nav className="flex items-center justify-center" aria-label="Progress">
                            <p className="text-sm font-medium text-gray-700 mt-10">
                                1 of 2
                            </p>
                            <ol role="list" className="ml-8 flex items-center space-x-5 mt-10">
                                <li>
                                    <span className="relative flex items-center justify-center" aria-current="step">
                                        <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                                            <span className="h-full w-full rounded-full bg-fp-purple-200" />
                                        </span>
                                        <span className="relative block h-2.5 w-2.5 rounded-full bg-fp-purple-600" aria-hidden="true" />
                                    </span>
                                </li>
                                <li>
                                    <span className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                                    </span>
                                </li>
                            </ol>
                        </nav>
                    </div>}
                    {bothInformationStep === 1 && <div className="text-center text-2xl font-medium text-gray-800">
                        Contribute to a child's portfolio
                        <nav className="flex items-center justify-center" aria-label="Progress">
                            <p className="text-sm font-medium text-gray-700 mt-10">
                                2 of 2
                            </p>
                            <ol role="list" className="ml-8 flex items-center space-x-5 mt-10">
                                <li>
                                    <span className="block h-2.5 w-2.5 rounded-full bg-fp-purple-600 hover:bg-fp-purple-900">
                                    </span>
                                </li>
                                <li>
                                    <span className="relative flex items-center justify-center" aria-current="step">
                                        <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                                            <span className="h-full w-full rounded-full bg-fp-purple-200" />
                                        </span>
                                        <span className="relative block h-2.5 w-2.5 rounded-full bg-fp-purple-600" aria-hidden="true" />
                                    </span>
                                </li>
                            </ol>
                        </nav>
                    </div>}
                </> } */}

                {(values.usageIntent === 0 || values.usageIntent === 2) && <div className="overflow-auto flex-1 grow my-auto p-5 px-10 text-lg text-gray-700">
                    <p className="text-sm leading-7 mb-7">A Junior Stocks and Shares ISA is a tax-efficient children's investment account which allows you to put aside up to £9,000 a year tax free on behalf of your child, giving them a head start when it comes to their financial future.</p>
                    <p className="text-sm leading-7 mb-7">
                        <ul className="list-disc ml-5">
                            <li>No minimum investment</li>
                            <li>Up to £9,000 tax free returns</li>
                            <li>Low fees and no hidden costs</li>
                        </ul>
                    </p>
                    <p className="text-sm leading-7 mb-7">Contributions made on behalf of your child are used to buy various types of investments and they don't pay tax on any capital growth, interest or dividends they receive.</p>
                    <p className="text-md leading-7 mb-7">It's important to note that the value of the Junior Stocks and Shares ISA can go down as well as up.</p>
                </div>}
                {/* {values.usageIntent === 1 && <div className="overflow-auto flex-1 grow my-auto p-5 px-10 ">
                    <p className="text-sm leading-7 mb-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam id leo in vitae turpis massa sed. Enim diam vulputate ut pharetra sit. </p>
                    <p className="text-sm leading-7 mb-7">Dui id ornare arcu odio ut sem nulla pharetra diam. Risus feugiat in ante metus dictum at. Ornare lectus sit amet est placerat in egestas erat imperdiet. Ut lectus arcu bibendum at varius vel pharetra vel turpis. Tempus iaculis urna id volutpat lacus laoreet. Vestibulum morbi blandit cursus risus at ultrices. Interdum varius sit amet mattis vulputate enim. Viverra nam libero justo laoreet sit. Cras semper auctor neque vitae tempus quam pellentesque. Cursus in hac habitasse platea. </p>
                    <p className="text-sm leading-7 mb-7">Non tellus orci ac auctor. Lacus luctus accumsan tortor posuere ac ut. Sed lectus vestibulum mattis ullamcorper velit sed. Iaculis nunc sed augue lacus viverra.</p>
                </div>} */}
                {/* {values.usageIntent === 2 && <>

                    {bothInformationStep === 0 && <div className="overflow-auto flex-1 grow my-auto p-5 px-10 ">
                        <p className="text-sm leading-7 mb-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam id leo in vitae turpis massa sed. Enim diam vulputate ut pharetra sit. </p>
                        <p className="text-sm leading-7 mb-7">Dui id ornare arcu odio ut sem nulla pharetra diam. Risus feugiat in ante metus dictum at. Ornare lectus sit amet est placerat in egestas erat imperdiet. Ut lectus arcu bibendum at varius vel pharetra vel turpis. Tempus iaculis urna id volutpat lacus laoreet. Vestibulum morbi blandit cursus risus at ultrices. Interdum varius sit amet mattis vulputate enim. Viverra nam libero justo laoreet sit. Cras semper auctor neque vitae tempus quam pellentesque. Cursus in hac habitasse platea. </p>
                        <p className="text-sm leading-7 mb-7">Non tellus orci ac auctor. Lacus luctus accumsan tortor posuere ac ut. Sed lectus vestibulum mattis ullamcorper velit sed. Iaculis nunc sed augue lacus viverra.</p>
                    </div>}

                    {bothInformationStep === 1 && <div className="overflow-auto flex-1 grow my-auto p-5 px-10 ">
                        <p className="text-sm leading-7 mb-7">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam id leo in vitae turpis massa sed. Enim diam vulputate ut pharetra sit. </p>
                        <p className="text-sm leading-7 mb-7">Dui id ornare arcu odio ut sem nulla pharetra diam. Risus feugiat in ante metus dictum at. Ornare lectus sit amet est placerat in egestas erat imperdiet. Ut lectus arcu bibendum at varius vel pharetra vel turpis. Tempus iaculis urna id volutpat lacus laoreet. Vestibulum morbi blandit cursus risus at ultrices. Interdum varius sit amet mattis vulputate enim. Viverra nam libero justo laoreet sit. Cras semper auctor neque vitae tempus quam pellentesque. Cursus in hac habitasse platea. </p>
                        <p className="text-sm leading-7 mb-7">Non tellus orci ac auctor. Lacus luctus accumsan tortor posuere ac ut. Sed lectus vestibulum mattis ullamcorper velit sed. Iaculis nunc sed augue lacus viverra.</p>
                    </div>}

                </>} */}

                <div>
                    {(values.usageIntent === 0 || values.usageIntent === 2) && <div className="flex flex-col align-end">
                        <Button className="mx-auto mb-2 w-64" onClick={() => setPage(2)} text="Continue"></Button>
                        <div className="text-fp-green-500 mx-auto" onClick={() => setPage(0)}>Back</div>
                    </div>}
                    {/* {values.usageIntent === 1 && <div className="flex flex-col align-end">
                        <Button className="mx-auto mb-2 w-64" onClick={() => submit()} text="Submit"></Button>
                        <div className="text-fp-green-500 mx-auto" onClick={() => setPage(0)}>Back</div>
                    </div>}
                    {values.usageIntent === 2 && <>
                        {bothInformationStep === 0 && <div className="flex flex-col align-end">
                            <Button className="mx-auto mb-2 w-64" onClick={() => setBothInformationStep(1)} text="Next"></Button>
                            <div className="text-fp-green-500 mx-auto" onClick={() => setPage(0)}>Back</div>
                        </div>}
                        {bothInformationStep === 1 && <div className="flex flex-col align-end">
                            <Button className="mx-auto mb-2 w-64" onClick={() => setPage(2)} text="Continue"></Button>
                            <div className="text-fp-green-500 mx-auto" onClick={() => setBothInformationStep(0)}>Back</div>
                        </div>}
                    </>} */}
                </div>
            </div>
        </div>
    }
    if (page === 2) {
        return <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
            <div className="flex flex-col h-full p-5">
                <div className="text-center text-2xl font-medium text-gray-800">
                    Set up a First Portfolio
                    {addChildStep === 0 && <span className="text-gray-500 text-sm block mt-5">Register your interest in setting up a First Portfolio account. You can register your interest for more accounts later.</span>}
                    {addChildStep === 1 && <span className="text-gray-500 text-sm block mt-5">What might be your preferred investment strategy to begin with? You could always change this later.</span>}

                </div>
                {addChildStep === 0 && <>
                    <nav className="flex items-center justify-center" aria-label="Progress">
                        <p className="text-sm font-medium text-gray-700 mt-10">
                            Step 1 of 2
                        </p>
                        <ol role="list" className="ml-8 flex items-center space-x-5 mt-10">
                            <li>
                                <span className="relative flex items-center justify-center" aria-current="step">
                                    <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                                        <span className="h-full w-full rounded-full bg-fp-purple-200" />
                                    </span>
                                    <span className="relative block h-2.5 w-2.5 rounded-full bg-fp-purple-600" aria-hidden="true" />
                                </span>
                            </li>
                            <li>
                                <span className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                                </span>
                            </li>
                        </ol>
                    </nav>
                    <div className="overflow-auto flex-1 grow my-auto p-5 px-10 mt-5">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Child's Full Name
                            </label>
                            <div className="mt-1">
                                <input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })}
                                    id="name"
                                    name="name"
                                    type="text"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-fp-green-500 focus:outline-none focus:ring-fp-green-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="mt-5">
                            <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                                Date Of Birth
                            </label>
                            <div className="mt-1">
                                <input value={values.dob} onChange={(e) => setValues({ ...values, dob: e.target.value })}
                                    id="dob"
                                    name="dob"
                                    type="date"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-fp-green-500 focus:outline-none focus:ring-fp-green-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="mt-5">
                        <Listbox value={values.goal} onChange={(e) => { console.log(e); setValues({ ...values, goal: e.name }) }}>
                            {({ open }) => (
                                <>
                                    <Listbox.Label className="block text-sm font-medium text-gray-700">What do you hope your child will accomplish through their First Portfolio account?</Listbox.Label>
                                    <div className="relative mt-1">
                                        <Listbox.Button className="h-10 relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                            <span className="block truncate">{values.goal}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md">
                                                {goals.map((goal) => (
                                                    <Listbox.Option
                                                        key={goal.id}
                                                        className={({ active }) =>
                                                            classNames(
                                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                            )
                                                        }
                                                        value={goal}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block')}>
                                                                    {goal.name}
                                                                </span>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'text-white' : 'text-indigo-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                        )}
                                                                    >
                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                    </div>
                    <div>
                        <div className="flex flex-col align-end">
                            <Button className="mx-auto mb-2 w-64" onClick={() => setAddChildStep(1)} text="Next"></Button>
                        </div>
                    </div>
                </>}
                {addChildStep === 1 && <>
                    <nav className="flex items-center justify-center" aria-label="Progress">
                        <p className="text-sm font-medium text-gray-700 mt-10">
                            Step 2 of 2
                        </p>
                        <ol role="list" className="ml-8 flex items-center space-x-5 mt-10">
                            <li>
                                <span className="block h-2.5 w-2.5 rounded-full bg-fp-purple-600 hover:bg-fp-purple-900">
                                </span>
                            </li>
                            <li>
                                <span className="relative flex items-center justify-center" aria-current="step">
                                    <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                                        <span className="h-full w-full rounded-full bg-fp-purple-200" />
                                    </span>
                                    <span className="relative block h-2.5 w-2.5 rounded-full bg-fp-purple-600" aria-hidden="true" />
                                </span>
                            </li>
                        </ol>
                    </nav>
                    <div className="overflow-auto flex-1 grow mt-5">
                        <div className="overflow-hidden bg-white shadow sm:rounded-md">
                            <ul role="list" className="divide-y divide-gray-200">
                                {riskOptions.map((option) => (
                                    <li key={option.id}>
                                        <div onClick={() => submit(option.id)} className="block hover:bg-gray-50">
                                            <div className="flex items-center px-4 py-4 sm:px-6">
                                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                                    <div>
                                                        <div className="flex text-md">
                                                            {option.icon}
                                                            <p className="font-medium text-fp-gray-500">{option.title}</p>
                                                        </div>
                                                        <div className="mt-2 flex">
                                                            <div className="flex items-center text-sm text-fp-green-700">
                                                                <p>
                                                                    {option.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ml-5 flex-shrink-0">
                                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col align-end">
                            <div className="text-fp-green-500 mx-auto" onClick={() => setAddChildStep(0)}>Back</div>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    }

}