import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Button from "./components/button";
import logo from './f-p-logo.png'

export default function Splash() {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("token")) {
            navigate('/home')
        }
    }, [])

    return <>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="h-screen flex flex-col">
                <div className="flex-1">
                    <div className="p-5">
                        <img className="mx-auto mt-44 max-w-xs" src={logo}></img>
                    </div>
                </div>
                <div className="p-5 text-center mb-20">
                    <Button link="register" className="w-full" text="Start Investing" widthFull={true}></Button>
                    <p className="mt-3 text-gray-500">Already have an account? <a className="text-gray-800" href="/login">Sign In</a></p>
                </div>
            </div>
        </div>
    </>
}
