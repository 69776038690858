import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Button from "./components/button";
import logo from './f-p-logo.png'
import QRCode from "react-qr-code";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";

export default function InviteFriends() {

    const navigate = useNavigate();
    const [viewModel, setViewModel] = useState({});
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        async function fetchData() {
            await fetch(`${process.env.REACT_APP_API_URL}/private/invite`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'bearer ' + localStorage.getItem("token")
                })
            }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                if (x.redirect) {
                    navigate(x.redirect);
                }
                setLoaded(true);
                setViewModel(x);
            })
        }
        fetchData();


    }, [])

    if (!loaded) return <div></div>

    return <>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">

            <div className="h-screen flex flex-col">
                <div className="flex-1">
                    <div className="p-5 text-center">
                        <img className="mx-auto mt-12 max-w-xs" src={logo}></img>
                        <p className="text-gray-700 mt-5 text-xl">Invite friends to join First Portfolio.</p>
                        <p className="text-gray-700 mt-5 mb-10">Ask friends to sign up using your personal invite link (or scan the QR code) and they will be linked to your children - ready to start gifting!</p>

                        <CopyToClipboard text={`https://app.firstportfolio.com/i?i=${viewModel.inviteCode}`}
                            onCopy={() => toast.info("Copied To Clipboard!")}>
                            <span className="cursor-pointer underline text-fp-purple-700 text-xl">app.firstportfolio.com/i?i={viewModel.inviteCode}</span>
                        </CopyToClipboard>

                        <div>
                            <QRCode className="bg-white mx-auto mt-12" value={`https://app.firstportfolio.com/i?i=${viewModel.inviteCode}`} />
                        </div>
                    </div>

                    
                </div>
                <div className="text-fp-green-500 text-center mb-20" onClick={() => navigate('/home')}>Back to Dashboard</div>
            </div>
            <ToastContainer position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
        </div>
    </>
}
