import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from './f-p-logo.png'

export default function Register() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [values, setValues] = useState({});
  const [error, setError] = useState("")

  const submit = async () => {

    if (values.confirm !== values.password) {
      setError("Passwords do not match");
      return;
    }

    await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ user: values, invitedByCode: searchParams.get("i") })
    }).then(res => { return res.json() }).then(x => {
      if (x.error) {
        if (x.error == "email_in_use") {
          setError("Email already in use. Please sign in or register with a different email.");
        }
      }
      else if (x.token) {
        localStorage.setItem("token", x.token);
        window.location.replace("/home")
      }
      else {
        setError("Registration failed. Please check your details and try again.")
      }
    })
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-24 text-center text-3xl font-bold tracking-tight text-gray-900">Register for an account</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            {searchParams.get("i") && <a href={`/login?i=${searchParams.get("i")}`} className="font-medium text-fp-green-500 hover:text-fp-green-500">
              sign in
            </a>}
            {!searchParams.get("i") && <a href="/login" className="font-medium text-fp-green-500 hover:text-fp-green-500">
              sign in
            </a>}
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-red-600 text-center">
                  {error}
                </label>
              </div>

              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Full Name
                </label>
                <div className="mt-1">
                  <input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })}
                    id="name"
                    name="name"
                    type="text"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-fp-green-500 focus:outline-none focus:ring-fp-green-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-fp-green-500 focus:outline-none focus:ring-fp-green-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-fp-green-500 focus:outline-none focus:ring-fp-green-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="confirm" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input value={values.confirm} onChange={(e) => setValues({ ...values, confirm: e.target.value })}
                    id="confirm"
                    name="confirm"
                    type="password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-fp-green-500 focus:outline-none focus:ring-fp-green-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="text-gray-400">By registering for an account you agree to our <a className='text-gray-600' href="https://firstportfolio.com/privacy" target="_blank">privacy policy</a></div>
              <div>
                <button
                  onClick={() => submit()}
                  type="button"
                  className="flex w-full justify-center rounded-md border border-transparent bg-fp-green-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fp-green-500 focus:outline-none focus:ring-2 focus:ring-fp-green-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
