import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Navigate, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import Button from "./components/button";
import logo from './f-p-logo.png'
import QRCode from "react-qr-code";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";

export default function Invite() {

    const navigate = useNavigate();
    const [viewModel, setViewModel] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {

        if (localStorage.getItem("token")) {
            async function linkUsers() {
                await fetch(`${process.env.REACT_APP_API_URL}/private/link-users/${searchParams.get("i")}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': 'bearer ' + localStorage.getItem("token")
                    })
                }).then(res => {
                    if (res.status === 401) {
                        localStorage.removeItem("token");
                        return res.json()
                    }
                    else {
                        navigate('/home');
                    }
                })
            }
            linkUsers();
        }
    }, [])

    return <>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">

            <div className="h-screen flex flex-col">
                <div className="flex-1">
                    <div className="p-5 text-center">
                        <img className="mx-auto mt-12 max-w-xs" src={logo}></img>
                        <p className="text-gray-700 mt-10 text-xl">You've been invited to join First Portfolio.</p>
                        <p className="text-gray-700 mt-10">Click register below to sign up for a new First Portfolio account. If you already have an account click login below and you will be automatically linked up.</p>
                    </div>
                    <div className="flex flex-col p-10">
                        <Button className="mb-5" onClick={() => navigate('/register?i=' + searchParams.get("i"))} text="Register for a new account"></Button>
                        <Button onClick={() => navigate('/login?i=' + searchParams.get("i"))} text="Login to your existing account"></Button>
                    </div>

                </div>

            </div>
        </div>
    </>
}
