import { Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./layout";
import Login from "./login";
import Register from "./register";
import Splash from "./splash";
import Setup from "./setup";
import Dashboard from "./dashboard";
import AddChild from "./add-child";
import InviteFriends from "./invite-friends";
import Invite from "./invite";
import EditChild from "./edit-child";
import Logout from "./logout";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Splash />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/setup" element={<Setup />} />
      <Route path="/home" element={<Dashboard />} />
      <Route path="/add-child" element={<AddChild />} />
      <Route path="/edit-child/:id" element={<EditChild />} />
      <Route path="/invite" element={<InviteFriends />} />
      <Route path="/i" element={<Invite />} />
    </Routes>
  )
}
