import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './components/button';

export default function Dashboard() {

    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [viewModel, setViewModel] = useState({});

    useEffect(() => {

        async function fetchData() {
            await fetch(`${process.env.REACT_APP_API_URL}/private/home`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'bearer ' + localStorage.getItem("token")
                })
            }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
                if (x.redirect) {
                    navigate(x.redirect);
                }
                setLoaded(true);
                setViewModel(x);
            })
        }
        fetchData();


    }, [])

    if (!loaded) return <div></div>

    return (
        <div className="flex flex-col min-h-screen">
            <div className="">
            
            </div>
            <div className="absolute top-10 right-2 text-white tracking-wider mt-2">
               
            </div>

            <div className="bg-gray-100 p-5 pt-12 shadow-md flex flex-row">
                <div className="flex-1 text-gray-800 font-bold text-xl truncate">
                    Hi {viewModel.name ? viewModel.name.split(' ')[0] : ''}
                </div>
                <Button onClick={() => navigate('/invite')} text="Invite Friends"></Button>
            </div>

            <p className="text-sm text-gray-50 px-8 py-4 text-center bg-gray-800">We're still getting ready to start taking investments. You can manage your children below, and when we go live you will be able to set up a Junior Stocks and Shares ISA for them!</p>

            <div className="p-5 border-t border-gray-200 flex-1">
                <div className="font-bold text-xl text-gray-800 mb-3 tracking-wide ">
                    <span className="mt-2 inline-block">Your Children</span>

                    <Button onClick={() => navigate('/add-child')} text="Add Child" className="float-right"></Button>
                </div>
                <div className="text-xs text-gray-600 mb-5">You will be able to contribute to your children's Junior Stocks and Shares ISA as a one off gift or on a regular basis</div>
                {viewModel.children.map(child => <>
                    <div onClick={() => navigate('/edit-child/' + child._id)} className="p-6 py-8 rounded-lg bg-gradient-to-br from-fp-purple-300 to-fp-purple-600 text-gray-50 mb-5 shadow-lg">
                        {/* <div className="relative">
                            <div className="absolute top-0 right-0 text-xs opacity-60">JISA</div>
                        </div> */}
                        <div className="font-bold text-3xl mb-5">{child.name}</div>
                        <div className="text-xs opacity-60">Risk Appetite</div>
                        <div className="font-semibold text-2xl">{child.riskAppetite === 0 ? 'Cautious' : child.riskAppetite === 1 ? 'Balanced Bag' : child.riskAppetite === 2 ? 'Confident' : ''}</div>

                        <div className="relative">
                            <div className="absolute bottom-0 right-0 text-xs opacity-60"><ArrowRightIcon className="w-8 h-8" /></div>
                        </div>
                    </div>
                </>)}

                <div className="font-bold text-xl mb-3 text-gray-800 tracking-wide ">
                    <span className="mt-2 inline-block">Children You Know</span>
                </div>
                {viewModel.linkedChildren && viewModel.linkedChildren.length > 0  && <div className="text-xs text-gray-600 mb-5">You will be able to gift investments to these children as a one off gift or on a regular basis</div>}
                {viewModel.linkedChildren && viewModel.linkedChildren.map(child => <>
                    <div className="p-6 py-8 rounded-lg bg-gradient-to-br from-fp-green-400 to-fp-green-600 text-gray-50 mb-5 shadow-lg">
                        <div className="font-semibold text-2xl">{child.name}</div>


                    </div>
                </>)}
                {!viewModel.linkedChildren.length && <div className="text-gray-600 text-sm text-center">You aren't linked to anyone elses children at the moment. Invite friends or use their invite link/QR code to link yourself with them. <br/><Button className="mt-5" onClick={() => navigate('/invite')} text="Invite Friends"></Button></div>}
                {viewModel.linkedChildren.length > 0 && <div className="text-gray-600 text-sm text-center">When we go live we'll be in touch! In the meantime feel free to invite the parents of any other children you would like to gift<br/><Button className="mt-5" onClick={() => navigate('/invite')} text="Invite Friends"></Button></div>}
                
            </div>
            <div onClick={() => navigate('/logout')} className="text-center text-sm font-semibold text-gray-400 mb-5">logout</div>
        </div>

    );
}